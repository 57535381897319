/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html {
  height: 100%;
}
body{
  height: 100%;
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: 0px;
  background-color: #000018;
}
#root {
  position: relative;
  width: 100%;
  min-height: 100%;
}
light{
  margin: 20px 40px;
  font-size: 1.2rem;
  letter-spacing: 01px;
  background: #000000;
}
h1, h2, h3, h4, ul, li, a, button, p, label, div, footer{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  color: white;
}
div {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}
title{
  margin: 0;
  padding: 20px 20px;
  font-family: 'Inter', sans-serif;
}
input{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
}

ul{
  list-style-type: none;
}
ul li img{
  width: 64px;
}
a{
  text-decoration: none;
}
nav{
  display: flex;
  width: 100%;
  max-width: 900px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #000018;
  margin-left: auto;
  margin-right: auto;
}
nav h1{
  display: flex;
  align-items: center;
}
nav ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav li{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav li a{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  display: block;
  padding: 10px 16px;
  letter-spacing: 2px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.login-btn {
  margin-left: auto;
}
.signup-btn {
  margin-right: auto;
}
.signup-button {
  width: 50%;
}
.login-button {
  width: 50%;

}
.btn{
  border-radius: 36px;
  background: #ff00d4;
}
form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: transparent;
}
editor h2{
  font-size: 2.4em;
  font-weight: 900;
  margin-bottom: 40px;
}
.left-panel {
  float: left; /* or display: inline-block; */
  width: 200px; /* set a desired width for the panel */
}
.left-panel header {
  width: 200px;
  height: 120px;
  background-color: var(--dark-color-a);
}
.left-panel h1 {
  font-size: 1.5em;
  display: flex;
  align-items: center;
  color: white;
}
editor{
  width: 40%;
  margin: 0 auto;
  padding: 30px;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.1);
  border-radius: 10px;
  background: #efefef;
}
input{
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  font-size: 1em;
  width: 100%;
}
label{
  display: flex;
  margin: 5px 0 10px;
}
button{
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  background: #eb185b;
  border:0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  letter-spacing: 2px;
  gap: 10px;
}
.error{
  color: #ff0099;
  margin: 10px 2px;
  font-size: 0.8em;
  font-weight: bold;
}
header{
  display: flex;
  max-width: 800px;
  align-items: center;
}
header img{
  width: 250px;
  margin-right: 40px;
}
header h2{
  font-size: 3em;
  margin-bottom: 10px;
}
header h3{
  font-size: 1.6em;
  margin-bottom: 10px;
  margin-left: 2px;
  color: rgb(0, 0, 0);
}
header .btn{
  margin-top: 20px;
  padding: 12px 18px;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  font-size: 0.8em;
}
table{
  background: rgb(255, 255, 255);
  padding: 30px;
  width: 100%;
}
table a{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  color: #000000;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 15px;
}
.back-button{
  width: 20%;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.back-button h3{
  font-size: .75em;
  font-weight: 300;
  margin-left: 2px;
  color: #818181;
}
.nav-logo {
  display: flex;
  box-sizing: border-box;
  width: 60%;
  justify-content: center;
}
.nav-logo img {
  display: flex;
  justify-content: center;
  width: 60%;
  max-width: 200px;
}
.bars-container {
  width: 20%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
.home-container {
  display: flex;
  flex-direction: column;
	width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  height: 100%;
	background-color: var(--color-background-body);
	overflow: hidden;
}
.home-logo {
  display: flex;
  width: 100%;
  justify-content: center;
}
.home-logo img {
  display: flex;
  justify-content: center;
  width: 50%;
  max-width: 200px;
  padding: 5px;
}
.subheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.subheader h2 {
  font-size: .8em;
  text-align: center;
  color:rgb(255, 255, 255);
}
.subheader h3 {
  width: 300px;
  text-align: center;
  font-size: .7em;
  font-weight: 300;
  color: rgb(255, 255, 255);
}
.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
}
.terms button {
  margin: 0;
}
.terms h2 {
  font-size: 1em;
  text-align: center;
  color: white;
}
.terms h3 {
  text-align: left;
  font-size: .8em;
  font-weight: 300;
  color: white;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  max-width: 350px;
}
.login-container h2{
  padding: 10px;
  text-align: center;
  font-size: 1.3em;
  color: rgb(255, 255, 255);
}
.login-container h3 {
  padding: 10px;
  text-align: center;
  font-size: .8em;
  font-weight: 300;
  color: rgb(255, 255, 255);
}
.login-container form {
  min-width: 200px;
  width: 100%;
  box-sizing: border-box;
}
.login-container label {
  width: 100%;
}
.login-container input[type="text"],
.login-container input[type="password"] {
  font-size: 16px;
  padding: 15px;
  border: none;
  border: 1px solid rgb(35, 29, 107);
  outline: none;
  background-color: rgb(14, 12, 44);
  text-align: left;
  width: 100%;
}
.login-container button {
  color:rgb(255, 255, 255);
  font-size: .7em;
}
.beta-disclaimer {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.beta-disclaimer h3 {
  font-weight: 200;
  font-size: .8em;
  text-align: center;
  margin-top: 5px;
}
.auth-error-message {
  display: inline-block;
  width: 100%;
  padding: 10px;
}
.auth-error-message h3 {
  font-size: .8em;
  font-weight: 300;
  color: red;
  padding: 0;
}
.auth-error-message h4 {
  font-size: 0.8em;
  font-weight: 300;
  color: rgb(0, 205, 78);
  text-align: center;
}
.auth-error-message h4:hover {
  cursor: pointer;
}
.success-message {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
}
.success-message h3 {
  font-size: .8em;
  font-weight: 300;
  color: rgb(0, 190, 22);
}
.signup-link-container {
  display: flex;
  min-width: 300px;
    width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.signup-link-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  background: radial-gradient(circle, #0756b5, #1c27f8);

  border:0;
  text-transform: uppercase;
  display: block;
  margin-top: 20px;
  letter-spacing: 2px;
  color: black;
}
.forgot-password {
  color: rgb(216, 216, 216);
  font-size: .7em;
  font-weight: 0;
}
.or-divider {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.or-divider hr {
  flex: 1;
  height: 1px;
  width: 100px;
  background-color: #ddd;
  margin-left: 10px;
  margin-right: 10px;
}
.or-divider span {
  font-weight: bold;
  font-size: .8em;
  color: #666;
}
.loading {
  margin-top: 300px;
  margin-bottom: 300px;
  display: flex;
  justify-content: center;
  align-self: center;
}
.loading img {
  width: 10%;
}
.channels-header {
  background: linear-gradient(to bottom right, #071fbc, #340055);
  filter: grayscale(50%);
  padding: 5px 0px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  position: sticky; /* Add this line */
  top: 49px; /* Adjust the value based on the height of your Navbar */
  z-index: 1; /* Add this line */
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);

}
.channels-header h1 {
  font-size: .8em;
  text-align: center;
  color:rgb(241, 241, 241);
}
.channels-filter-container {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.channels-filter-options {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.channels-filter-search {
  margin-left: 10px;
  width: 60%;
  max-width: 200px;
  height: 32px;
  display: flex;
}
.channels-filter-search input {
  font-size: .7em;
  padding: 15px;
  border: none;
  border-radius: 8px;
  border: 1px solid #4c30bd;
  outline: none;
  background-color: rgb(14, 12, 44);
  text-align: left;
  width: 100%;
}
.channels-filter-dropdown {
  width: 40%;
  max-width: 150px;
  margin-left: 10px;
  height: 32px;
}
.channels-filter-dropdown select {
  width: 100%;
  height: 32px;
  text-align: center;
  border: 1px solid #4c30bd;
  border-radius: 8px;
  background-color: rgb(14, 12, 44);
  font-size: .7em;
  color: #ffffff;
}

.channels-filter-dropdown select:focus {
  outline: none;
  border: 1px solid #4c30bd;
}

.channels-filter-dropdown option {
  background-color: rgb(14, 12, 44);
  color: #ffffff;
}
.channels-filter-clear {
  width: 25%;
  max-width: 100px;
  height: 32px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}
.channels-filter-clear button {
 background-color: #511d78;
 font-size: .5em;
 height: 32px;
 width: 75%;
 letter-spacing: 1px;
 margin: 0
}

.channels-container {
  height: calc(100vh - 94px);
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}
.channels-container::-webkit-scrollbar {
  width: 8px;
  background-color: #0d112d;
}

.channels-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust transparency here */
}

ul.channel-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  list-style: none;
  background-color: #000018;
  padding: 0;
  margin: 0;
  opacity: 0;
}

ul.channel-list.fade-in {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}
.channel-list-row-back {
  height: 100%;
  width: 100%;
  background-color: #0d112d;
  transition: background-color 0.5s ease-in-out;

  border: none; /* Remove the existing border */
  /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset; this is for light mode*/
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5), 0px 0px 0px 1px rgba(14, 14, 14, 0.5) inset;
}
.channel-list-row-back.fade-in {
  background-color: #0d112d;
}
.channel-list-row {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .1);
}

.sports-vs-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  max-width: 45px;
  margin-right: 35px;
  margin-left: 30px;
}
.sports-vs-image img {
  padding-left: 2px;
  width: auto;
  height: auto;
}

.channel-list-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  margin-right: 15px;
  margin-left: 15px;
}
.channel-list-image img {
  width: 50px;
  height: auto;
}

.channel-list-name {
  margin-right: 10px;
}
.channel-list-program {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  margin-right: 10px;
}
.channel-list-program h3 {
  font-family: Inter, sans-serif;
  font-size: .9rem;
  font-weight: 500;
}
.channel-list-program-container {
  display: flex;
  align-items: center;
}
.channel-list-program-container img {
  width: 25px;
  margin-right: 10px;
}
.channel-list-count {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 18px;
}
.channel-list-count h3 {
  margin-left: 5px;
  font-family: Inter, sans-serif;
  font-size: .9rem;
  font-weight: 600;
  text-align: center;
}
.channel-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 1;
  background-color: transparent;
}

.channel-link button {
  background: transparent;
  margin: 0;
}

.channel-link h3 {
  color: rgb(225, 225, 225);
}

.channel-link img {
  max-width: 100%;
  max-width: 50px;
}

.channel-header-container {
  
  max-height: 150px;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}


.logo-header {
  height: 20%;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.channel-header {
  height: 100%;
  width: 100%;
  background: #1d1d1d;
  z-index: 2;
}

.channel-header h1 {
  text-align: center;
  padding-top: 20px;
}

.channel-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  background: linear-gradient(to right, #010b4c, #000332);
  filter: grayscale(50%);
}

.channel-name {
  justify-self: flex-start;
}

.channel-users {
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.channel-name h3 {
  font-size: .8em;
  margin-left: 5px;
  color: #f9f9f9;
}
.channel-users h3 {
  font-size: .75em;
  margin-left: 3px;
  font-weight: 300;
  text-align: center;
}
.users-icon {
  margin-top: 4px;
}
.user-count {
  width: 35px;
}
.program-box {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to bottom right, #071fbc, #7302b9);
}
.program-name{
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: grayscale(50%);
  align-items: flex-start;
  padding: 5px;
}
.program-name h2 {
  font-size: .9em;
  margin-left: 5px;
  margin-right: 5px;
  color: #f9f9f9;
  display: -webkit-box; /* add -webkit-box for Safari support */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* restrict to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis; /* add ellipsis to indicate text truncation */
  max-height: 4em; /* calculate based on font size and line-height */
}
.program-name h3 {
  font-size: .7em;
  font-weight: 400;
  margin-left: 5px;
  padding-top: 5px;
  display: -webkit-box; /* add -webkit-box for Safari support */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* restrict to 3 lines */
  overflow: hidden;
  text-overflow: ellipsis; /* add ellipsis to indicate text truncation */
}
.program-text {
  opacity: 0;
}
.program-text.fade-in {
  transition: opacity .1s ease-in;
  opacity: 1;
}
.share-button {
  display: flex;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  align-items: center;
}
footer{
  text-align: center;
  margin-top: 40px;
  color: #aaa;
}
.chat-container {
  position: absolute;
  top: 147px; /* Adjust the top offset to account for the static element's height */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 147px);
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  background-color: #000018;
  overflow: hidden;
}

.chat-window {
  flex-grow: 1; /* Allow the chat window to grow and fill the available space */
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in;
}
.chat-window.fade-in {
  opacity: 1;
}

/* .chat-window {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
} */

.chat-messages {
  box-sizing: border-box;
  padding: 5px 15px 5px;
  min-height: 100%;
  height: 300px;
  width: 100%;
  overflow-y: auto;
  grid-column: 2 / 3;
}
.chat-messages::-webkit-scrollbar {
  width: 10px; /* Adjust width as needed */
}
.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgba(49, 11, 84, 0.5); /* Adjust transparency here */
}
.chat-messages .message {
  margin-bottom: 3px;
  background-color: var(--light-color);
  border-radius: 0px;
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  align-items: flex-start;
}

.chat-messages .message .text {
  /* styles for the text span */
  color: white;
  font-size: .9rem;
  font-weight: 0;
  line-height: 1.5;
}
.chat-messages .message .text img {
  width: 24px;
  height: 24px;
}

.chat-messages .message .systemText {
  /* styles for the text span */
  padding: 3px;
  font-size: .8rem;
  font-style: italic;
}

.chat-messages .message .user {
  font-size: .9rem;
  font-weight: bold;
  /* styles for the user span */
}
.chat-messages .message .user-icon {
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 3px;
}
.chat-messages .message .user-icon img {
  width: 18px;
  height: 18px;
}
.chat-form-container {
  /* position: absolute;
  bottom: 0;
  justify-self: end; */
	background: linear-gradient(to bottom right, #a200ba, #2a0060);
  /* grid-column: 2 / 3; */
  display: flex;
  height: 80px;
  width: 100%;
}
.chat-form-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border-radius: 5px;
  max-height: 40px;
  align-self: center;
  margin-right: 10px;
  margin-left: 5px;
  background-color: #f9155d;
}

.chat-form {
  display: flex;
  flex-grow: 1; /* add this to make the textarea expand to fill the available space */
  margin: 0 auto;
  padding: 10px 5px 10px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--dark-color) var(--light-color);
}
.chat-form textarea[type="text"] {
  width: 80%;
  padding: 10px;
  padding-right: 2.7rem;
  border: none;
  border-radius: 13px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  font-family: Inter, sans-serif;
  font-size: 1rem;
  color: white;
  background-color: rgb(26, 19, 41);
  flex-grow: 1;
  resize: none; /* disable native textarea resizing */
  height: 45px;
  max-height: 100px;
  overflow-y: scroll;
  white-space: pre-wrap; /* allow text to wrap */
}
.chat-form textarea::-webkit-scrollbar {
  width: 8px;
}

.chat-form textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust transparency here */
}
.chat-form textarea.autosize {
  height: auto;
}

/* scrollbar styles */
.chat-form::-webkit-scrollbar {
  width: 8px;
}

.chat-form::-webkit-scrollbar-thumb {
  background-color: var(--dark-color);
}

.chat-form::-webkit-scrollbar-track {
  background-color: var(--light-color);
}
.chat-form textarea.autosize {
  height: auto;
  min-height: 4px; /* set minimum height to match the initial height */
}
.chat-form textarea[type="submit"] {
  width: 100%;
  padding: 10px;
  background: #0083e8;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.textarea-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
#submitMessage {
  z-index: 3;
}

.emoji-icon-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.emoji-icon {
  font-size: 20px; /* Adjust the font size as needed */
  color: grey;
}
.chat-form .btn {
  width: 70px;
  padding: 10px;
  background: #0083e8;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 65%;
  max-width: 350px;
  height: 100%;
  background-color: #000625;
  transition: right 0.3s ease-in-out;
  z-index: 20;
}

.menu.open {
  right: 0;
}
.menu-top {
  height: 50%;
  width: 100%;
}
.menu-x {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 5%;
  max-height: 5%;
  padding-top: 3px;
  padding-left: 4px;
  box-sizing: border-box;
}
.menu-header {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: .7em;
}
.menu-login {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  width: 90%;
  font-size: .7em;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
}
.menu-login button {
  margin: 0;
}
.menu-username {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #210842;
}
.menu-username h1 {
  padding-left: 10px;
  color: white;
  font-size: .9em;
}
.menu-list {
  margin: 20px;
  padding-left: 10px;
  display: flex;
}
.menu-list li {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.menu-list h3 {
  padding-left: 15px;
  font-size: .7em;
  color: white;
}
.menu-darkMode {
  display: flex;
  align-items: center;
}
.menu-darkMode h3 {
  padding-right: 10px
}
.menu-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
}

.menu-logout {
  display: flex;
  justify-content: space-evenly;
}
.menu-bottom-links {
  display: flex;
  width: 100%;
  padding-top: 20px;
  justify-content: space-evenly;
}
.menu-bottom-links h3 {
  font-size: .6em;
  font-weight: 300;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.5s;
}
.overlay.visible {
  opacity: .5;
}
.settings-container {
  margin-top: 20px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.settings-container form {
  width: 85%;
}
.settings-container label {
  width: 100%;
}
.settings-container input {
  font-size: .8em;
  padding: 15px;
  border: none;
  border: 1px solid rgb(35, 29, 107);
  outline: none;
  background-color: rgb(14, 12, 44);
  text-align: left;
}
.settings-container button {
  width: 100%;
  font-size: .6em;
}
.settings-container .cancel {
  margin-top: 10px;
  width: 50%;
  margin-left: 0;
  margin-right: 0;
}
.settings-container .cancel button {
  width: 100%;
  text-align: center;
  background-color: #010b4c;
  margin: 0;
}
.settings-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: .5rem;
  max-width: 100%;
}
.settings-grid h2 {
  font-size: .9em;
}
.settings-grid h3 {
  font-size: .9em;
  font-weight: 200;
}
.settings-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.settings-username {
  padding: 10px;
  padding-left: 20px;
  height: 30px;
}
.settings-email {
  padding: 10px;
  padding-left: 20px;
  height: 60px;
}
.settings-email button {
  background-color: #0083e8;
  width: 200px;
  font-size: .6em;
}
.settings-password {
  padding: 10px;
  padding-left: 20px;
  height: 60px;
}
.settings-password button {
  background-color: #0083e8;
  width: 200px;
  font-size: .6em;
}
.settings-delete {
  margin-top: 50px;
  padding: 15px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.settings-delete button {
  font-size: .6em;
  width: 200px;
}
.settings-delete h3 {
  font-size: .8em;
  text-align: center;
}
.settings-confirm-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.settings-confirm-delete h3 {
  padding: 20px;
}
.settings-confirm-delete input {
  width: 300px;
  background-color: #000018;
  margin-bottom: 10px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.share-modal {
  position: relative;
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  align-content: center; /* Align content at the top */
  background-color: rgb(24, 6, 60);
  border: 1px solid #565656;
  height: 350px;
  width: 300px;
  border-radius: 10px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: .9em;
  z-index: 21;
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: translateY(0);
}
.share-modal.visible {
  opacity: 1;
  transform: translateY(10%);
}
.share-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: auto;
}
.share-content button {
  display: flex;
  justify-content: flex-start;
  margin-left: 70px;
  width: 90%;
  padding: 6px;
  border: 1px solid #2a2a2a;
  border-radius: 10px;
  text-transform: none;
  letter-spacing: 0px;
}
.share-header {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.4em;
  padding: 10px 10px 30px;
}
.modal-content {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  align-content: center; /* Align content at the top */
  background-color: rgb(26, 27, 36, 1);
  border: 1px solid #3f3f3f;
  height: 20%;
  max-height: 60px;
  min-height: 30px;
  width: 70%;
  max-width: 300px;
  min-width: 200px;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 86px;
}

.emoji-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center emojis horizontally */
  align-items: center; /* Center emojis vertically */
}

.emoji-item {
  flex: calc(20% - 5px) 0 calc(20% - 5px); /* Adjust the width of each emoji item */
  margin-left: .3rem;
  margin-right: .3rem;
}
.emoji-item img {
  height: 36px;
  width: 36px;
}
.scroll-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
}
.scroll-modal-content {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  align-content: center; /* Align content at the top */
  height: 20px;
  min-height: 30px;
  width: 50%;
  max-width: 300px;
  min-width: 200px;
  border-radius: 10px;
  margin-bottom: 90px;
  pointer-events: all;
}
.scroll-modal button {
  background-color: rgba(21, 16, 40, 1);
  border-radius: 20px;
  border: 1px solid #8497ff;
  width: 100%;
  margin: 0;
  font-size: .6em;
  font-weight: 300;
  color: #8497ff;
}
.poll-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  position: fixed;
  top: 147px;
  width: 100%;
  max-width: 400px;
  height: 85px;
  z-index: 1;
  opacity: 0;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 1);

}
.poll-open {
  transform: translateY(0%);
  opacity: 1
}
.poll-active {
  transform: translateY(-81%);
  opacity: 1
}
.poll-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgb(32, 5, 69);
  height: 85px;
  width: 100%;
  /* box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.3); */
}
.poll-alert {
  width: 85%;
  height: 20px;
  background-color: rgb(32, 5, 69);
  border-radius: 2px;
  display: flex;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  padding-top: 4px;
  box-sizing: border-box;
}
.poll-alert.fade-in {
  opacity: 1;
}
.poll-alert.fade-out {
  opacity: 0;
}
.poll-alert-close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 5%;
  background-color: #6a0538;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
}
.poll-alert-time {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10%;
  background-color: #04002f;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  padding-left: 5px;
  padding-right: 5px;
}
.poll-alert-time h3 {
  text-align: right;
}
.poll-alert-text {
  display: flex;
  align-items: flex-start;
  width: 85%;
  overflow: hidden;
}
.poll-alert h3 {
  display: inline-block;
  font-size: .7em;
  font-weight: 300;
  white-space: nowrap;
}
.poll-alert-text h3 {
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.poll-tab {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: #eb0d1f;
  width: 15%;
  border-radius: 5px;
  margin-left: 5px;
  transition: transform 0.5s ease-in-out;
}
.tab-closed {
  transform: translateY(-100%);
}
.poll-element {
  width: 100%;
  min-height: 65px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-template-rows: auto 1fr auto;
  gap: 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}
.poll-element.fade-in {
  opacity: 1;
}
.poll-element.fade-out {
  opacity: 0;
}
.poll-options {
  display: flex;
  justify-content: center;
  width: 95%;
  height: 100%;
  padding: 5px;
  gap: 5px;
  z-index: 19;
}
.poll-option-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  border-radius: 15px;
  background: linear-gradient(to bottom, #2e0098, #260072);
  padding: 4px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.poll-option-button h3 {
  font-size: .6em;
  font-weight: 300;
  text-align: center;
  line-height: 1em;
}
@keyframes fadeSelected {
  0% {
    background: linear-gradient(to bottom, #2e0098, #260072);
  }
  50% {
    background: white;
  }
  100% {
    background: linear-gradient(to bottom, #860098, #720055);
  }
}
.poll-option-button.clicked {
  animation: fadeSelected 0.3s linear forwards;
}
.poll-question {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 100%;
  background: linear-gradient(to bottom, #26005b, #19004b);
  box-sizing: border-box;
}
.poll-question h3 {
  font-size: .8em;
  font-weight: bold;
}
.poll-prompt-big {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 4;
  gap: 5px;
  pointer-events: none;
  transition: opacity 0.5s ease-in;
}
.poll-prompt-big.fade-in {
  opacity: 1;
}
.poll-prompt-big.fade-out {
  opacity: 0;
}
.poll-prompt-big h3 {
  font-size: .7em;
  text-align: center;
  font-weight: 300;
}
.poll-prompt-big h2 {
  font-size: 1em;
  text-align: center;
  padding: 3px;
}
.poll-results {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 4px;
  pointer-events: none;
  transition: opacity 0.5s ease-in;
}
.poll-results.fade-in {
  opacity: 1;
}
.poll-results.fade-out {
  opacity: 0;
}
.poll-results-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  height: 80px;
  padding-left: 10px;
  box-sizing: border-box;
}
.poll-results-options .container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}
.poll-results-options h3 {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .7em;
  font-weight: 300;
}
.poll-results-bars {
  position: absolute;
  left: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 0 10px 0;
  box-sizing: border-box;
  overflow: hidden;
  width: 60%;
  height: 80px;
}
.poll-results-single-bar {
  height: 15px;
}
.poll-results-single-bar .fill {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: red;
  width: 0%;
  transition: width 0.5s ease-in-out;
  animation: growFromRight 0.5s ease-in-out;
  animation-play-state: paused;
}
.poll-results-single-bar h3 {
  margin-left: 3px;
  text-align: center;
  font-size: .8em;
  color: rgb(255, 255, 255);
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}
@keyframes growFromRight {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.poll-sheen {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  overflow: hidden;
}

.poll-sheen:before {
  content: "";
  position: absolute;
  top: 0%;
  left: -100%;
  width: 200%;
  height: 400%;
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.6) 50%, transparent 100%);
  transform: rotate(-30deg);
  pointer-events: none;
  animation: sheenAnimation 1.5s linear forwards;
}
@keyframes sheenAnimation {
  0% {
    transform: rotate(-30deg) translateX(-150%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-30deg) translateX(150%);
    opacity: 0;
  }
}
.feedback-container {
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 500px;
  justify-self: center;
  align-items: center;
  margin-top: 20px;
}
.feedback-options select {
  background-color: #210842;
  border: 1px solid #4c30bd;
  color: white;
  font-size: .7em;
}
.feedback-container h3 {
  margin-right: auto;
  font-size: .8em;
  font-weight: 200;
}
.feedback-container h2 {
  font-size: 1.1em;
  color:rgb(0, 190, 22);
}
.feedback-container textarea {
  width: 100%;
  padding: 5px 10px 5px;
  border: none;
  border-radius: 5px;
  border: 1px solid #4c30bd;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
  font-family: Inter, sans-serif;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  background-color: #210842;
  flex-grow: 1;
  resize: none; /* disable native textarea resizing */
  height: 80px;
  max-height: 100px;
  overflow-y: scroll;
  white-space: pre-wrap; /* allow text to wrap */
}

.feedback-container textarea.autosize {
  height: auto;
}

/* scrollbar styles */
.feedback-container::-webkit-scrollbar {
  width: 8px;
}

.feedback-container::-webkit-scrollbar-thumb {
  background-color: var(--dark-color);
}

.feedback-container::-webkit-scrollbar-track {
  background-color: var(--light-color);
}
.feedback-container textarea.autosize {
  height: auto;
  min-height: 4px; /* set minimum height to match the initial height */
}
.feedback-container button {
  display: flex;
  max-width: 250px;
  justify-content: center;
  align-items: center;
  background-color: #0083e8;
  border:0;
  text-transform: uppercase;
  display: block;
  margin-top: 20px;
  letter-spacing: 2px;
  color: black;
}
.changelog-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 20px 40px 20px;
  max-width: 600px;
}
.changelog-container h2 {
  text-align: left;
  font-size: .8em;
}
.changelog-container h3 {
  text-align: left;
  font-size: .7em;
  font-style: italic;
  font-weight: 300;

}

.profile-container {
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.profile-header h2{
  font-size: 1em;
}
.profile-list {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-option {
  display: flex;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 2fr 3fr;
  margin-top: 20px;
  padding:5px;
  box-sizing: border-box;
}
.profile-option h2 {
  font-size: .8em;
}
.profile-option label {
  font-size: .8em;
  margin: 0;
}
.profile-option select {
  background-color: #210842;
  border: 1px solid #4c30bd;
  color: white;
  font-size: .8em;
  width: 200px;
}
.settings-update {
  width: 200px;
}
.settings-update button{
  background-color: #511d78;
  font-size: .6em;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  letter-spacing: 1px;;
}

#google {
  margin: 0;
  padding: 0;
}
#google button {
  background-color: rgb(123, 123, 123);
  margin-top: 15px;
  margin-bottom: 5px;
  color:rgb(255, 255, 255);
  font-size: .7em;
}
#login {
  padding-top: 0px;
}
#create-account {
  margin: 0;
  padding: 0;
}
#create-account button {
  background-color: rgb(0, 46, 231);
  margin-top: 15px;
  margin-bottom: 5px;
  color:rgb(255, 255, 255);
  font-size: .7em;
}

#resend {
  color: white;
  font-size: .7em;
  padding-top: 5px;
}